import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/register",
    meta: {
      reqauth: false,
      isMenu: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/appdownload",
    name: "appdownload",
    component: () => import("../views/AppView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
